// React related imports
import React, {useState, useEffect, useRef, useCallback} from "react";
import {useParams, useNavigate} from "react-router-dom";
import Helmet from "react-helmet";

// Third-party library imports
import axios from "axios";
import dayjs from "dayjs";
import {
	Stepper,
	Step,
	Grid,
	Button,
	Radio,
	FormControl,
	RadioGroup,
	FormControlLabel,
	TextField,
	FormHelperText,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {useForm, FormProvider, Controller} from "react-hook-form";

// Local components and functions
import QuantityInput from "../input/QuantityInput";
import SectionBox from "./SectionBox";
import ContactInformationForm from "./form/ContactInformationForm";
import {
	QontoConnector,
	QontoStepContent,
	QontoStepIcon,
	QontoStepLabel,
} from "./QontoConnector";

// Utility functions
import {checkDisabled} from "./utils";

function OrderDate() {
	const {date} = useParams();
	const navigate = useNavigate();
	const [dishes, setDishes] = useState(null);
	const [sides, setSides] = useState(null);
	const [totalDishes, setTotalDishes] = useState(0);
	const [reOrderConfirmationOpen, setReOrderConfirmationOpen] =
		useState(false);

	const [showDss, setShowDss] = useState(false);

	const [deliveryDisabled, setDeliveryDisabled] = useState(false);
	const [restDisabled, setRestDisabled] = useState(false);

	const [completedSteps, setCompletedSteps] = useState({
		epik: false,
		gev: false,
		salat: true,
		paral: false,
	});

	const methods = useForm({
		mode: "onBlur",
	});

	const {setValue, register} = methods;

	useEffect(() => {
		register("recaptchaValue");
	}, []);

	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref4 = useRef(null);
	const [height1, setHeight1] = useState(0);
	const [height2, setHeight2] = useState(0);
	const [height3, setHeight3] = useState(0);

	const formattedDate = date.split("-").reverse().join("-");

	const fetchMenus = useCallback(() => {
		const formattedDate = date;
		axios
			.get("/api/menus/daily-menu", {
				params: {
					date: formattedDate,
				},
			})
			.then((res) => {
				if (
					!checkDisabled(
						res.data.dishes,
						dayjs(formattedDate.split("-").reverse().join("-")),
						res.data.dateOptions?.orderBefore || null
					)
				) {
					setDishes(res.data.dishes);
				} else {
					navigate("/");
				}
				setDeliveryDisabled(
					res.data.dateOptions?.deliveryDisabled || false
				);
				setRestDisabled(res.data.dateOptions?.restDisabled || false);
			})
			.catch((err) => {
				navigate("/");
			});
		axios
			.get("/api/menus/side-menu")
			.then((res) => {
				setSides(res.data);
			})
			.catch((err) => {});
	}, [date, navigate]);

	const notSaturdayOrSunday =
		dayjs(formattedDate).day() !== 6 && dayjs(formattedDate).day() !== 0;

	useEffect(() => {
		fetchMenus();

		const contactInfo = localStorage.getItem("contactInfo");
		if (contactInfo) {
			const savedInfo = JSON.parse(contactInfo);
			for (const key in savedInfo) {
				if (key === "paralavi" && !notSaturdayOrSunday) continue;
				if (key !== "saveInformation") {
					key === "paralavi" &&
						notSaturdayOrSunday &&
						savedInfo[key] === "DSS Pickup" &&
						setShowDss(true);

					setValue(key, savedInfo[key]);
				}
			}
		}
	}, [fetchMenus, setValue]);

	const getDistances = useCallback((ref1, ref2, setHeight) => {
		const rect1 = ref1.current.getBoundingClientRect();
		const rect2 = ref2.current.getBoundingClientRect();
		const dx = rect2.x - rect1.x;
		const dy = rect2.y - rect1.y;
		const distance = Math.sqrt(dx * dx + dy * dy);
		setHeight(distance - 30);
	}, []);

	const getAllDistances = useCallback(() => {
		getDistances(ref1, ref2, setHeight1);
		getDistances(ref2, ref3, setHeight2);
		getDistances(ref3, ref4, setHeight3);
	}, [getDistances, ref1, ref2, ref3, ref4]);

	useEffect(() => {
		getAllDistances();
	}, [dishes, sides, getAllDistances]);

	const handleEpikCompletion = useCallback((completed) => {
		setCompletedSteps((prevSteps) => ({
			...prevSteps,
			epik: completed,
		}));
	}, []);

	const handleDishChange = useCallback((id, value) => {
		setTotalDishes((prev) => {
			const newDishes = {...prev};
			newDishes[id] = value;
			const total = Object.values(newDishes).reduce((a, b) => a + b, 0);

			setCompletedSteps((prevSteps) => ({
				...prevSteps,
				gev: total > 0,
			}));

			return newDishes;
		});
	}, []);

	const handleRadioChange = useCallback(
		(event) => {
			setCompletedSteps((prevSteps) => ({
				...prevSteps,
				paral: true,
			}));
			if (event.target.value === "DSS Pickup") {
				setShowDss(true);
			} else {
				setShowDss(false);
				setValue("dss", "");
			}
		},
		[setValue]
	);

	const submitForm = () => {
		methods.handleSubmit(onSubmit)();
	};

	const onSubmit = async (data) => {
		if (!completedSteps.gev) {
			alert(
				"Παρακαλούμε επιλέξτε τουλάχιστον ένα γεύμα πριν παραγγείλετε"
			);
		} else {
			if (data.saveInformation) {
				localStorage.setItem(
					"contactInfo",
					JSON.stringify({
						name: data.name,
						phone: data.phone,
						email: data.email,
						paralavi: data.paralavi,
						dss: data.dss,
					})
				);
			}

			if (reOrderConfirmationOpen) {
				data.confirm = "true";
			}

			data.date = formattedDate;

			const token = await window.grecaptcha
				.execute("6Lf4-EUoAAAAAHeksinbedRHrxilK7yoc8x28Yq7", {
					action: "submit",
				})
				.catch((err) => {
					console.log(err);
				});
			data.recaptchaValue = token;
			// Submit the data to the API
			axios
				.post("/api/orders/submit-order", data)
				.then((response) => {
					// Handle successful response here (e.g. navigate to a success page, display a success message, etc.)
					navigate(`/success`, {
						state: {code: response.data.code},
					});
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 409) {
							// Order already exists, confirm reordering
							setReOrderConfirmationOpen(true);
							return;
						}
					}
					console.log(error);
					alert(
						"Παρουσιάστηκε κάποιο σφάλμα. Ανανεώστε τη σελίδα και δοκιμάστε ξανά."
					);
					// Handle error response here (e.g. display an error message to the user)
				});
		}
	};

	return (
		<div style={{textAlign: "left"}}>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js?render=6Lf4-EUoAAAAAHeksinbedRHrxilK7yoc8x28Yq7"
					async
					defer
				></script>
			</Helmet>
			<div style={{position: "relative"}}>
				<Button
					onClick={() => navigate("/")}
					startIcon={<ArrowBackIcon />}
					sx={{
						position: "absolute",
						left: 0, // align it to the left of the container
						top: "50%", // vertically center it
						transform: "translateY(-50%)", // adjust for exact centering
					}}
					size="large"
				>
					Πισω
				</Button>
				<h1 style={{textAlign: "center"}}>
					Παραγγελία για{" "}
					{dayjs(formattedDate).locale("el").format("dddd DD/MM")}
				</h1>
			</div>

			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid
						container
						style={{
							textAlign: "left",
							fontSize: "1.1rem",
						}}
						spacing={2}
					>
						<Grid item xs={12} sm={9}>
							<div ref={ref1}>
								<SectionBox title="ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ">
									<ContactInformationForm
										onCompleted={handleEpikCompletion}
									/>
								</SectionBox>
							</div>

							<div ref={ref2}>
								<SectionBox
									title="ΓΕΥΜΑΤΑ"
									style={{justifyContent: "space-between"}}
									id="dishes"
								>
									{dishes &&
										dishes.map((dish) => (
											<Controller
												key={dish._id}
												name={`dishes.${dish._id}`}
												control={methods.control}
												defaultValue={0}
												rules={{required: true}}
												render={({field}) => (
													<QuantityInput
														label={dish.name}
														value={field.value}
														onChange={(value) => {
															field.onChange(
																value
															);
															handleDishChange(
																dish._id,
																value
															);
														}}
													/>
												)}
											/>
										))}
								</SectionBox>
							</div>

							<div ref={ref3}>
								<SectionBox title="ΣΑΛΑΤΕΣ - ΔΙΑΦΟΡΑ">
									{sides &&
										sides.map((side) => (
											<Controller
												key={side._id}
												name={`sides.${side._id}`}
												control={methods.control}
												defaultValue={0}
												rules={{required: true}}
												render={({field}) => (
													<QuantityInput
														label={side.name}
														value={field.value}
														onChange={(value) =>
															field.onChange(
																value
															)
														}
													/>
												)}
											/>
										))}
								</SectionBox>
							</div>

							<div ref={ref4}>
								<SectionBox
									title="ΣΤΟΙΧΕΙΑ ΠΑΡΑΛΑΒΗΣ"
									style={{textAlign: "left"}}
								>
									<FormControl>
										<Controller
											name="paralavi"
											control={methods.control}
											defaultValue="LAF Xanthis"
											rules={{required: true}}
											render={({field}) => (
												<RadioGroup
													{...field}
													onChange={(e) => {
														field.onChange(e);
														handleRadioChange(e);
													}}
												>
													{!restDisabled && (
														<FormControlLabel
															value="LAF Xanthis"
															control={<Radio />}
															label="ΛΑΦ Ξάνθης Εστιατόριο"
														/>
													)}
													<FormControlLabel
														value="LAF Xanthis Pickup"
														control={<Radio />}
														label="ΛΑΦ Ξάνθης Πακέτο"
													/>
													{/* If it's saturday or sunday, don't display these */}
													{dayjs(
														formattedDate
													).day() !== 6 &&
														dayjs(
															formattedDate
														).day() !== 0 &&
														!deliveryDisabled && (
															<>
																<FormControlLabel
																	value="Anapsiktirio Pickup"
																	control={
																		<Radio />
																	}
																	label="Αναψυκτήριο Πακέτο"
																/>
																<FormControlLabel
																	value="DSS Pickup"
																	control={
																		<Radio />
																	}
																	label="Δ'ΣΣ Πακέτο (Στρατόπεδο Αποστολίδη)"
																/>
															</>
														)}
												</RadioGroup>
											)}
										/>

										<Controller
											name="dss"
											control={methods.control}
											defaultValue=""
											rules={{required: showDss}}
											render={({field}) => (
												<TextField
													{...field}
													label="Σημείο Παραλαβής"
													variant="standard"
													InputLabelProps={{
														shrink: true,
														sx: {
															fontSize: "1.4rem",
														},
													}}
													InputProps={{
														style: {
															marginTop: 20,
														},
													}}
													sx={{
														marginBottom: 1,
														marginTop: 1,
														display:
															showDss === true
																? "block"
																: "none",
													}}
													fullWidth
													error={
														!!methods.formState
															.errors.dss
													}
												/>
											)}
										/>

										<FormHelperText
											sx={{
												display:
													showDss === true
														? "block"
														: "none",
											}}
										>
											Συμπληρώστε το ακριβές σημείο στο
											στρατόπεδο Αποστολίδη, στο οποίο
											θέλετε να γίνει η παράδοση του
											πακέτου
										</FormHelperText>
									</FormControl>
								</SectionBox>
								<SectionBox
									title="ΣΗΜΕΙΩΣΕΙΣ"
									style={{textAlign: "left"}}
								>
									<Controller
										name="notes"
										control={methods.control}
										render={({field}) => (
											<TextField
												{...field}
												label="Σημειώσεις"
												variant="standard"
												multiline
												rows={3}
												InputLabelProps={{
													shrink: true,
													sx: {
														fontSize: "1.4rem",
													},
												}}
												InputProps={{
													style: {
														marginTop: 20,
													},
												}}
												fullWidth
											/>
										)}
									/>
								</SectionBox>
							</div>
						</Grid>

						<Grid
							item
							xs={0}
							sm={3}
							sx={{display: {xs: "none", sm: "block"}}}
						>
							<Stepper
								// activeStep={1}
								// activeStep={activeStep}
								orientation="vertical"
								connector={<QontoConnector />}
								nonLinear
								sx={{
									my: 2,
								}}
							>
								<Step
									key={1}
									completed={completedSteps["epik"]}
								>
									<QontoStepLabel
										StepIconComponent={QontoStepIcon}
									>
										Στοιχεία Επικοινωνίας
									</QontoStepLabel>
									<QontoStepContent sx={{height: height1}} />
								</Step>
								<Step key={2} completed={completedSteps["gev"]}>
									<QontoStepLabel
										StepIconComponent={QontoStepIcon}
									>
										Γεύματα
									</QontoStepLabel>
									<QontoStepContent sx={{height: height2}} />
								</Step>
								<Step
									key={3}
									completed={completedSteps["salat"]}
								>
									<QontoStepLabel
										StepIconComponent={QontoStepIcon}
									>
										Σαλάτες - Διάφορα
									</QontoStepLabel>
									<QontoStepContent sx={{height: height3}} />
								</Step>
								<Step
									key={4}
									completed={completedSteps["paral"]}
								>
									<QontoStepLabel
										StepIconComponent={QontoStepIcon}
									>
										Στοιχεία Παραλαβής
									</QontoStepLabel>
								</Step>
							</Stepper>
						</Grid>
						<Grid item xs={12} sm={9}>
							<Controller
								name="confirm"
								control={methods.control}
								defaultValue="false"
								render={({field}) => (
									<FormControlLabel
										control={
											<Checkbox
												checked={field.value === "true"}
												onChange={(e) =>
													field.onChange(
														e.target.checked
															? "true"
															: "false"
													)
												}
												required
											/>
										}
										label="Δηλώνω υπεύθυνα ότι θα καταβάλω το αντίτιμο των μερίδων την παρασκευή των οποίων αιτούμαι με την παρούσα δήλωση ακόμα και στην περίπτωση που για οποιοδήποτε λόγο δεν προσέλθω στη Λέσχη - σημείο παραλαβής να παραλάβω το γεύμα."
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={9}>
							<Controller
								name="saveInformation"
								control={methods.control}
								defaultValue={true}
								render={({field}) => (
									<FormControlLabel
										control={
											<Checkbox
												checked={field.value}
												onChange={(e) =>
													field.onChange(
														e.target.checked
													)
												}
											/>
										}
										label="Αποθήκευση Στοιχείων"
									/>
								)}
							/>
						</Grid>
						<Grid item sx={{textAlign: "center"}} xs={12} sm={9}>
							<LoadingButton
								variant="contained"
								color="primary"
								sx={{marginBottom: 2}}
								type="submit"
								size="large"
								endIcon={<ShoppingCartCheckoutIcon />}
								loading={methods.formState.isSubmitting}
								loadingPosition="end"
							>
								Παραγγελια
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			<Dialog
				open={reOrderConfirmationOpen}
				onClose={() => setReOrderConfirmationOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Επιβεβαίωση Παραγγελίας
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Υπάρχει ήδη παραγγελία για αυτή την ημερομηνία και τα
						στοιχεία επικοινωνίας. Θέλετε να την παραγγείλετε ξανά;
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setReOrderConfirmationOpen(false)}
						color="primary"
					>
						Πισω
					</Button>
					<Button onClick={submitForm} color="primary" autoFocus>
						Επιβεβαίωση
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default OrderDate;
