// React related imports
import React, {useState, useEffect, useRef, useCallback} from "react";
import {useParams, useNavigate} from "react-router-dom";

// Third-party library imports
import axios from "axios";
import dayjs from "dayjs";
import {
	Grid,
	Button,
	Radio,
	FormControl,
	RadioGroup,
	FormControlLabel,
	TextField,
	FormHelperText,
	Autocomplete,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Checkbox,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {useForm, FormProvider, Controller} from "react-hook-form";

// Local components and functions
import QuantityInput from "../input/QuantityInput";
import SectionBox from "../order/SectionBox";
import ContactInformationForm from "./components/orders/ContactInformationForm";

// Utility functions
import {checkDisabled} from "../order/utils";

function OrderDate() {
	const {date} = useParams();
	const navigate = useNavigate();
	const [dishes, setDishes] = useState(null);
	const [sides, setSides] = useState(null);
	const [totalDishes, setTotalDishes] = useState(0);
	const [userData, setUserData] = useState([]);

	const [showDss, setShowDss] = useState(false);

	const [deliveryDisabled, setDeliveryDisabled] = useState(false);
	const [restDisabled, setRestDisabled] = useState(false);

	const [addedDish, setAddedDish] = useState(false);

	const [reOrderConfirmationOpen, setReOrderConfirmationOpen] =
		useState(false);

	const methods = useForm({
		mode: "onBlur",
	});

	const {
		control,
		handleSubmit,
		register,
		formState: {errors},
	} = methods;

	const {setValue} = methods;

	const formattedDate = date.split("-").reverse().join("-");

	const fetchMenus = useCallback(() => {
		const formattedDate = date;
		axios
			.get("/api/menus/daily-menu", {
				params: {
					date: formattedDate,
				},
			})
			.then((res) => {
				if (
					!checkDisabled(
						res.data.dishes,
						dayjs(formattedDate.split("-").reverse().join("-")),
						res.data.dateOptions?.orderBefore || null,
						true
					)
				) {
					setDishes(res.data.dishes);
				} else {
					navigate("/");
				}
				setDeliveryDisabled(
					res.data.dateOptions?.deliveryDisabled || false
				);
				setRestDisabled(res.data.dateOptions?.restDisabled || false);
			})
			.catch((err) => {
				navigate("/");
			});
		axios
			.get("/api/menus/side-menu")
			.then((res) => {
				setSides(res.data);
			})
			.catch((err) => {});
	}, [date, navigate]);

	const fetchUserData = useCallback(() => {
		axios
			.get("/api/orders/unique-users")
			.then((res) => {
				setUserData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		fetchMenus();
		fetchUserData();
	}, [fetchMenus, fetchUserData]);

	const handleDishChange = useCallback((id, value) => {
		setTotalDishes((prev) => {
			const newDishes = {...prev};
			newDishes[id] = value;
			const total = Object.values(newDishes).reduce((a, b) => a + b, 0);

			setAddedDish(total > 0);

			return newDishes;
		});
	}, []);

	const handleRadioChange = useCallback(
		(event) => {
			if (event.target.value === "DSS Pickup") {
				setShowDss(true);
			} else {
				setShowDss(false);
				setValue("dss", "");
			}
		},
		[setValue]
	);

	const submitForm = () => {
		methods.handleSubmit(onSubmit)();
	};

	const onSubmit = (data) => {
		if (!addedDish) {
			alert(
				"Παρακαλούμε επιλέξτε τουλάχιστον ένα γεύμα πριν παραγγείλετε"
			);
		} else {
			data.date = formattedDate;
			// Submit the data to the API
			if (reOrderConfirmationOpen) {
				data.confirm = "true";
			}
			axios
				.post("/api/orders/submit-order", data)
				.then((response) => {
					// Handle successful response here (e.g. navigate to a success page, display a success message, etc.)
					navigate(`/success`, {
						state: {code: response.data.code, isAdmin: true},
					});
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 409) {
							// Order already exists, confirm reordering
							setReOrderConfirmationOpen(true);
							return;
						}
					}
					console.log(error);
					// Handle error response here (e.g. display an error message to the user)
				});
		}
	};

	const handleNameChange = (e, newValue) => {
		const selected = userData.find((d) => d.name === newValue);

		if (selected) {
			setValue("name", selected.name);
			setValue("phone", selected.phone);
		}
	};

	const handlePhoneChange = (e, newValue) => {
		const selected = userData.find((d) => d.phone === newValue);
		if (selected) {
			setValue("phone", selected.phone);
			setValue("name", selected.name);
		}
	};

	const filterOptions = (options, {inputValue}) => {
		if (inputValue.length < 5) {
			return [];
		}
		return options.filter((option) =>
			option.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	return (
		<div style={{textAlign: "left"}}>
			<div style={{position: "relative"}}>
				<Button
					onClick={() =>
						navigate("/diaxirisi/paraggelies/prosthiki/")
					}
					startIcon={<ArrowBackIcon />}
					sx={{
						position: "absolute",
						left: 0, // align it to the left of the container
						top: "50%", // vertically center it
						transform: "translateY(-50%)", // adjust for exact centering
					}}
					size="large"
				>
					Πισω
				</Button>
				<h1 style={{textAlign: "center"}}>
					Παραγγελία για{" "}
					{dayjs(formattedDate).locale("el").format("dddd DD/MM")}
				</h1>
			</div>

			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid
						container
						style={{
							textAlign: "left",
							fontSize: "1.1rem",
						}}
						justifyContent={"center"}
						spacing={2}
					>
						<Grid item xs={12} sm={9}>
							<SectionBox title="ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ">
								<Controller
									name="name"
									control={control}
									rules={{
										required: true,
										maxLength: 60,
										pattern: /^[A-Za-zΑ-Ωα-ωάέήίόύώ\s]+$/,
									}}
									render={({field}) => (
										<Autocomplete
											{...field}
											options={userData.map(
												(d) => d.name
											)}
											value={field.value || ""}
											onInputChange={(
												e,
												newValue,
												reason
											) => {
												if (reason === "input")
													field.onChange(newValue);
											}}
											onChange={(_, newValue) =>
												handleNameChange(_, newValue)
											}
											filterOptions={filterOptions}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											freeSolo
											renderInput={(params) => (
												<TextField
													{...params}
													label="Ονοματεπώνυμο"
													InputLabelProps={{
														shrink: true,
														sx: {
															fontSize: "1.4rem",
														},
													}}
													sx={{marginBottom: 4}}
													error={!!errors.name}
													helperText={
														errors.name &&
														"Δώσε ονοματεπώνυμο"
													}
													variant="standard"
													required
												/>
											)}
										/>
									)}
								/>
								<Controller
									name="phone"
									control={control}
									rules={{
										required: true,
										maxLength: 60,
										pattern: /^[0-9]{10}$/,
									}}
									render={({field}) => (
										<Autocomplete
											{...field}
											options={userData.map(
												(d) => d.phone
											)}
											value={field.value || ""}
											onInputChange={(
												e,
												newValue,
												reason
											) => {
												if (reason === "input")
													field.onChange(newValue);
											}}
											onChange={(_, newValue) =>
												handlePhoneChange(_, newValue)
											}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											freeSolo
											filterOptions={filterOptions}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Τηλέφωνο"
													InputLabelProps={{
														shrink: true,
														sx: {
															fontSize: "1.4rem",
														},
													}}
													// sx={{marginBottom: 4}}
													error={!!errors.phone}
													helperText={
														errors.phone &&
														"Δώσε τηλέφωνο"
													}
													variant="standard"
													required
													type="tel"
												/>
											)}
										/>
									)}
								/>
							</SectionBox>

							<SectionBox
								title="ΓΕΥΜΑΤΑ"
								style={{justifyContent: "space-between"}}
								id="dishes"
							>
								{dishes &&
									dishes.map((dish) => (
										<Controller
											key={dish._id}
											name={`dishes.${dish._id}`}
											control={methods.control}
											defaultValue={0}
											rules={{required: true}}
											render={({field}) => (
												<QuantityInput
													label={dish.name}
													value={field.value}
													onChange={(value) => {
														field.onChange(value);
														handleDishChange(
															dish._id,
															value
														);
													}}
												/>
											)}
										/>
									))}
							</SectionBox>

							<SectionBox title="ΣΑΛΑΤΕΣ - ΔΙΑΦΟΡΑ">
								{sides &&
									sides.map((side) => (
										<Controller
											key={side._id}
											name={`sides.${side._id}`}
											control={methods.control}
											defaultValue={0}
											rules={{required: true}}
											render={({field}) => (
												<QuantityInput
													label={side.name}
													value={field.value}
													onChange={(value) =>
														field.onChange(value)
													}
												/>
											)}
										/>
									))}
							</SectionBox>

							<SectionBox
								title="ΣΤΟΙΧΕΙΑ ΠΑΡΑΛΑΒΗΣ"
								style={{textAlign: "left"}}
							>
								<FormControl>
									<Controller
										name="paralavi"
										control={methods.control}
										defaultValue="LAF Xanthis"
										rules={{required: true}}
										render={({field}) => (
											<RadioGroup
												{...field}
												onChange={(e) => {
													field.onChange(e);
													handleRadioChange(e);
												}}
											>
												{!restDisabled && (
													<FormControlLabel
														value="LAF Xanthis"
														control={<Radio />}
														label="ΛΑΦ Ξάνθης Εστιατόριο"
													/>
												)}
												<FormControlLabel
													value="LAF Xanthis Pickup"
													control={<Radio />}
													label="ΛΑΦ Ξάνθης Πακέτο"
												/>
												{/* If it's saturday or sunday, don't display these */}
												{dayjs(formattedDate).day() !==
													6 &&
													dayjs(
														formattedDate
													).day() !== 0 &&
													!deliveryDisabled && (
														<>
															<FormControlLabel
																value="Anapsiktirio Pickup"
																control={
																	<Radio />
																}
																label="Αναψυκτήριο Πακέτο"
															/>
															<FormControlLabel
																value="DSS Pickup"
																control={
																	<Radio />
																}
																label="Δ'ΣΣ Πακέτο (Στρατόπεδο Αποστολίδη)"
															/>
														</>
													)}
											</RadioGroup>
										)}
									/>

									<Controller
										name="dss"
										control={methods.control}
										defaultValue=""
										rules={{required: showDss}}
										render={({field}) => (
											<TextField
												{...field}
												label="Σημείο Παραλαβής"
												variant="standard"
												InputLabelProps={{
													shrink: true,
													sx: {
														fontSize: "1.4rem",
													},
												}}
												InputProps={{
													style: {
														marginTop: 20,
													},
												}}
												sx={{
													marginBottom: 1,
													marginTop: 1,
													display:
														showDss === true
															? "block"
															: "none",
												}}
												fullWidth
												error={
													!!methods.formState.errors
														.dss
												}
											/>
										)}
									/>

									<FormHelperText
										sx={{
											display:
												showDss === true
													? "block"
													: "none",
										}}
									>
										Συμπληρώστε το ακριβές σημείο στο
										στρατόπεδο Αποστολίδη, στο οποίο θέλετε
										να γίνει η παράδοση του πακέτου
									</FormHelperText>
								</FormControl>
							</SectionBox>
							<SectionBox
								title="ΣΗΜΕΙΩΣΕΙΣ"
								style={{textAlign: "left"}}
							>
								<Controller
									name="notes"
									control={methods.control}
									render={({field}) => (
										<TextField
											{...field}
											label="Σημειώσεις"
											variant="standard"
											multiline
											rows={3}
											InputLabelProps={{
												shrink: true,
												sx: {
													fontSize: "1.4rem",
												},
											}}
											InputProps={{
												style: {
													marginTop: 20,
												},
											}}
											fullWidth
										/>
									)}
								/>
							</SectionBox>
						</Grid>

						<Grid item xs={12} sm={9}>
							<Controller
								name="confirm"
								control={methods.control}
								defaultValue="true"
								render={({field}) => (
									<FormControlLabel
										control={
											<Checkbox
												checked={field.value === "true"}
												onChange={(e) =>
													field.onChange(
														e.target.checked
															? "true"
															: "false"
													)
												}
												required
											/>
										}
										label="Δηλώνω υπεύθυνα ότι θα καταβάλω το αντίτιμο των μερίδων την παρασκευή των οποίων αιτούμαι με την παρούσα δήλωση ακόμα και στην περίπτωση που για οποιοδήποτε λόγο δεν προσέλθω στη Λέσχη - σημείο παραλαβής να παραλάβω το γεύμα."
									/>
								)}
							/>
						</Grid>

						<Grid item sx={{textAlign: "center"}} xs={12} sm={9}>
							<LoadingButton
								variant="contained"
								color="primary"
								sx={{marginBottom: 2}}
								type="submit"
								size="large"
								endIcon={<ShoppingCartCheckoutIcon />}
								loading={methods.formState.isSubmitting}
								loadingPosition="end"
							>
								Παραγγελια
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			<Dialog
				open={reOrderConfirmationOpen}
				onClose={() => setReOrderConfirmationOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Επιβεβαίωση Παραγγελίας
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Υπάρχει ήδη παραγγελία για αυτή την ημερομηνία και τα
						στοιχεία επικοινωνίας. Θέλετε να την παραγγείλετε ξανά;
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setReOrderConfirmationOpen(false)}
						color="primary"
					>
						Πισω
					</Button>
					<Button onClick={submitForm} color="primary" autoFocus>
						Επιβεβαίωση
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default OrderDate;
