import {useEffect, useState} from "react";
import axios from "axios";

// @mui
import {Container, Grid, Typography} from "@mui/material";

// components
import {
	WidgetSummary,
	TotalOrdersChart,
	OrdersPerPickupChart,
	TotalRevenueByDishTypeChart,
	AverageOrderValueChart,
	TotalRevenueSidesChart,
} from "./components/home";

//Icons
import ReceiptIcon from "@mui/icons-material/Receipt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EqualizerIcon from "@mui/icons-material/Equalizer";

function Dashboard() {
	const [data, setData] = useState({});

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		axios
			.get("/api/stats", {signal})
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			abortController.abort();
		};
	}, []);

	return (
		<Container>
			<Typography variant="h5" sx={{mb: 5}}>
				Γειά σας, καλώς ήρθατε.
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} md={3}>
					<WidgetSummary
						title="Σύνολο Παραγγελιών"
						total={data.totalOrdersCount}
						icon={<ReceiptIcon fontSize="large" />}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<WidgetSummary
						title="Έσοδα Συσσιτίου του Μήνα"
						total={data.totalDishRevenueOfMonth}
						icon={<MonetizationOnIcon fontSize="large" />}
						type="currency"
						color="success"
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<WidgetSummary
						title="Συνολικά Έσοδα Συσσιτίου"
						total={data.totalDishRevenue}
						icon={<AttachMoneyIcon fontSize="large" />}
						type="currency"
						color="info"
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<WidgetSummary
						title="Μέση Αξία Παραγγελιών του Μήνα"
						total={data.averageOrderValueOfMonth}
						icon={<EqualizerIcon fontSize="large" />}
						type="currency"
						color="warning"
					/>
				</Grid>

				<Grid item xs={12} md={6} lg={8}>
					<TotalOrdersChart data={data.totalOrders} />
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<OrdersPerPickupChart data={data.ordersPerPickup} />
				</Grid>

				<Grid item xs={12} md={4} lg={4}>
					<TotalRevenueByDishTypeChart
						data={data.totalRevenueByDishType}
					/>
				</Grid>
				<Grid item xs={12} md={8} lg={8}>
					<TotalRevenueSidesChart data={data.totalRevenueBySide} />
				</Grid>
			</Grid>
		</Container>
	);
}

export default Dashboard;
